import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsLoaderService {
    private scriptLoaded = false;

    constructor() {}

    loadGoogleMaps(apiKey: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.scriptLoaded) {
                resolve(); // Si el script ya está cargado, resolvemos la promesa
                return;
            }

            // Crear el script para Google Maps
            const script = document.createElement('script');
            script.src =
                'https://maps.googleapis.com/maps/api/js?key=' +
                environment.apigoogle +
                `&libraries=places,marker,geometry&language=ES&region=GT`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                this.scriptLoaded = true;
                resolve(); // Resolvemos la promesa cuando el script se ha cargado
            };

            script.onerror = (error: any) => {
                console.error(
                    'Error al cargar el script de Google Maps:',
                    error
                );
                reject(error); // Rechazamos la promesa en caso de error
            };

            document.head.appendChild(script);
        });
    }
}
