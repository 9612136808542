<nav
    class="navbar navbar-expand-lg navbar-light bg-primary fixed-top shadow-sm p-2">
    <div class="container-fluid p-1">
        <a href="https://forzadelivery.com" class="">
            <img
                width="160"
                height="33"
                src="assets/logos/forza-delivery-express-logo-white-md.png"
                class="imageLogo"
                alt="Logo Forza" />
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link nav-inicio">Inicio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-custom">Contáctanos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-custom">Tienda Virtual</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-custom">Enviar / Portal</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
