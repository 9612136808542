import {
    APP_INITIALIZER,
    ApplicationConfig,
    importProvidersFrom,
    provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GoogleMapsLoaderService } from 'src/shared/services/google-maps-loader/google-maps-loader.service';

// Función de inicialización que cargará el script de Google Maps
export function initializeGoogleMaps(gmapsService: GoogleMapsLoaderService) {
    return () => gmapsService.loadGoogleMaps(environment.apigoogle);
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: false }),
        provideRouter(routes),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeGoogleMaps,
            deps: [GoogleMapsLoaderService],
            multi: true,
        },
    ],
};
