import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'rastreo',
        loadComponent: () =>
            import('../pages/service-tracking/service-tracking.component').then(
                (m) => m.ServiceTrackingComponent
            ),

        children: [
            {
                path: ':guia',
                loadComponent: () =>
                    import(
                        '../pages/service-tracking/service-tracking.component'
                    ).then((m) => m.ServiceTrackingComponent),
            },
        ],
    },
    {
        path: 'guide-link/:token',
        loadComponent: () =>
            import(
                '../pages/guide-link-without-login/checkout/checkout.component'
            ).then((m) => m.CheckoutComponent),
    },
    {
        path: 'guide-link',
        loadComponent: () =>
            import(
                '../pages/guide-link-without-login/guide-link-without-login.component'
            ).then((m) => m.GuideLinkWithoutLoginComponent),
    },
    {
        path: ':token',
        loadComponent: () =>
            import('../pages/delivery-link/delivery-link.component').then(
                (m) => m.DeliveryLinkComponent
            ),
    },
    {
        path: 'pagina-no-encontrada',
        loadComponent: () =>
            import('../pages/not-found/not-found.component').then(
                (m) => m.NotFoundComponent
            ),
    },
    {
        path: '**',
        loadComponent: () =>
            import('../pages/not-found/not-found.component').then(
                (m) => m.NotFoundComponent
            ),
    },
];
